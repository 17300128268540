/** @jsx jsx */
import { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import ListingCardSpecs from '~/components/Listing/Card/Specs'
import Modal from '~/components/Generic/Modal'
import QuantityIncrementor from '~/components/Generic/QuantityIncrementor'
import { useCanClub } from '~/hooks/components/use-can-club'
import { toUsdCurrency } from '~/utils/formatters'

const CanClubCustomizeStepCategoryItem = ({ item }) => {
  const { products, updateProductQuantity } = useCanClub()
  const [isExpanded, setIsExpanded] = useState(false)

  const { description, name, price, product, sku, thumbnail } = item

  function handleChange(quantity) {
    if (quantity >= 0) {
      updateProductQuantity(sku, quantity)
    }
  }

  return (
    <Box>
      <Flex>
        <Box
          onClick={() => setIsExpanded(true)}
          mr={2}
          sx={{
            borderRadius: '8px',
            cursor: 'pointer',
            height: 'auto',
            maxWidth: ['104px', null, '125px'],
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <GatsbyImage
            image={thumbnail.gatsbyImageData}
            alt={thumbnail.description}
          />
        </Box>
        <Box sx={{ flexGrow: '1' }}>
          <Flex sx={{ flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flexGrow: '1' }}>
              <Button
                variant="reset"
                onClick={() => setIsExpanded(true)}
                sx={{ textAlign: 'left', width: '100%' }}
              >
                <Flex>
                  <Text as="h3" variant="title6" sx={{ flexGrow: '1' }}>
                    {name}
                  </Text>
                  <Text
                    color="brandPrimary"
                    variant="body3"
                    ml={1}
                    sx={{
                      textDecoration: 'underline',
                    }}
                  >
                    {'Details'}
                  </Text>
                </Flex>
              </Button>
              <Text as="p" variant="body3">
                <Text
                  as="span"
                  color="greyDark"
                  pr={1}
                  sx={{ textDecoration: 'line-through' }}
                >
                  {toUsdCurrency(price.regular, true)}
                </Text>
                <Text
                  as="strong"
                  color="brandTertiaryDark"
                  pr="6px"
                  sx={{ fontWeight: 'bold' }}
                >
                  {toUsdCurrency(price.discount, true)}
                </Text>
                <Text as="span" pr="6px">
                  {'/'}
                </Text>
                <Text as="span">{'250mL can'}</Text>
              </Text>
            </Box>
            <QuantityIncrementor
              handleChange={handleChange}
              quantity={products[sku].quantity || 0}
            />
          </Flex>
        </Box>
      </Flex>
      {description && (
        <Box
          variant="text.description"
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
          mt={1}
          sx={{ color: 'greyDark', p: { m: 0 } }}
        />
      )}
      {isExpanded && (
        <Modal onClose={() => setIsExpanded(false)} maxWidth="474px">
          <ListingCardSpecs
            description={product.kitDescription}
            image={product.mainImage}
            subtitle={product.subtitle}
            tags={product.tags}
            title={product.name}
          />
        </Modal>
      )}
    </Box>
  )
}

export default CanClubCustomizeStepCategoryItem
