/** @jsx jsx */
import { Fragment } from 'react'
import { Box, Flex, Heading, jsx, Spinner, Text } from 'theme-ui'
import { useCanClub } from '~/hooks/components/use-can-club'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'
import { getClubCanCount } from '~/utils/subscriptions'

const CanClubReviewStepSummary = ({
  displayItemTotal,
  displayPromotions,
  displayTotal,
  isLoading,
}) => {
  const { products } = useCanClub()
  const clubReviewUiText = useUiTextQuery('can-club', 'review')

  const quantity = getClubCanCount(products)

  return (
    <Box>
      <Box
        bg="backgroundLight"
        pt={1}
        pb="12px"
        px={3}
        sx={{ textAlign: 'center' }}
      >
        <Heading as="h3" mt={0} variant="body2">
          {`First shipment: ${quantity} cans`}
        </Heading>
      </Box>
      <Box py={2} px={[2, 3]}>
        {!isLoading && (
          <Fragment>
            <Flex as="p" mt={0} mb="4px" variant="text.body2">
              <Text as="span" sx={{ flexGrow: '1' }}>
                {'Subtotal:'}
              </Text>
              <Text as="span">{displayItemTotal}</Text>
            </Flex>
            {displayPromotions.map(({ id, label, displayAmount }) => (
              <Flex key={id} as="p" mt={0} mb="4px" variant="text.body2">
                <Text as="span" sx={{ flexGrow: '1' }}>
                  {label}:
                </Text>
                <Text as="span" sx={{ fontWeight: 'bold' }}>
                  {displayAmount}
                </Text>
              </Flex>
            ))}
            <Flex as="p" mt={0} mb="4px" variant="text.body2">
              <Text as="span" sx={{ flexGrow: '1' }}>
                {`Shipping:`}
              </Text>
              <Text as="span" sx={{ fontWeight: 'bold' }}>{`FREE`}</Text>
            </Flex>
            <Flex
              as="p"
              m={0}
              mt="12px"
              pt={1}
              pb="12px"
              variant="text.body2"
              sx={{
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'greyLight',
                fontWeight: 'bold',
              }}
            >
              <Text as="span" sx={{ flexGrow: '1' }}>
                {`Total:`}
              </Text>
              <Text as="span" color="brandTertiaryDark">
                {displayTotal}
              </Text>
            </Flex>
          </Fragment>
        )}
        {isLoading && (
          <Box py={3} sx={{ textAlign: 'center' }}>
            <Spinner
              color="brandTertiary"
              size="40"
              sx={{
                '@media (prefers-reduced-motion)': {
                  circle: {
                    animationIterationCount: 8,
                  },
                },
              }}
            />
          </Box>
        )}
        <Text
          as="p"
          mt={2}
          mb={0}
          variant="finePrint"
          sx={{ color: 'greyDark' }}
        >
          {clubReviewUiText.shipping}
        </Text>
      </Box>
    </Box>
  )
}

export default CanClubReviewStepSummary
