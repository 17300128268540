import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CanClubJoinPage from '~/components/CanClub/Page/Join'
import { useCanClub } from '~/hooks/components/use-can-club'
import {
  getClubCartDefaultItems,
  getClubJoinDefaultItems,
  getDoesCartHaveClubItems,
} from '~/utils/subscriptions'

// Signing up for a new subscription.
const CanClubJoinTemplate = ({ data, location, pageContext }) => {
  const {
    customizationIsCustomized,
    joinStep,
    joinSteps,
    joinType,
    joinTypeOption,
    productsDefaults,
    setCustomizationIsCustomized,
    setJoinInterval,
    setJoinStep,
    setJoinType,
    setProducts,
    setProductsDefaults,
    setQuantity,
    SUBSCRIPTION_TYPE_OPTIONS,
  } = useCanClub()
  const { cart } = useCart()

  const {
    subscriptionContent,
    allProductContentData: { nodes: allProductContent },
  } = data
  const { defaultQuantity, intervals } = subscriptionContent
  const defaultVariantsField = joinTypeOption?.field
  const { lineItems, tagList } = cart || {}

  const handleTunneledBack = () => {
    switch (joinStep) {
      case joinSteps[1]:
        setJoinStep(joinSteps[0])
        break

      case joinSteps[2]:
        setCustomizationIsCustomized(false)
        setJoinStep(joinSteps[1])
        setProducts(productsDefaults)
        break

      case joinSteps[3]:
        if (customizationIsCustomized) setJoinStep(joinSteps[2])
        else setJoinStep(joinSteps[1])
        break

      default:
        navigate('/can-club/')
        break
    }
  }

  useEffect(() => {
    const defaultItems = getClubJoinDefaultItems(
      subscriptionContent,
      allProductContent,
      defaultVariantsField
    )

    setProducts(defaultItems)
    setProductsDefaults(defaultItems)
  }, [
    allProductContent,
    defaultVariantsField,
    setProducts,
    setProductsDefaults,
    subscriptionContent,
  ])

  // Push the customer to the review step if they loaded/refreshed the page
  // with club items in the cart. The review step will empty and re-add
  // everything, which is helpful in case they'd refreshed mid-review.
  useEffect(() => {
    if (joinType || !lineItems || !getDoesCartHaveClubItems(lineItems)) return

    const defaultItems = getClubCartDefaultItems(
      lineItems,
      subscriptionContent,
      allProductContent
    )

    setProducts(defaultItems)
    setCustomizationIsCustomized(true)
    setJoinStep(joinSteps[3])

    // Pull the appropriate type option from the order tags.
    SUBSCRIPTION_TYPE_OPTIONS.forEach((typeOption) => {
      if (tagList.includes(typeOption.tag)) {
        setJoinType(typeOption.key)
      }
    })
  }, [
    allProductContent,
    joinSteps,
    joinType,
    lineItems,
    setCustomizationIsCustomized,
    setJoinStep,
    setJoinType,
    setProducts,
    subscriptionContent,
    SUBSCRIPTION_TYPE_OPTIONS,
    tagList,
  ])

  useEffect(() => {
    if (!intervals?.[0]?.length || !intervals?.[0]?.unit?.[0]) return

    setJoinInterval(intervals[0].length, intervals[0].unit[0])
  }, [intervals, setJoinInterval])

  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity, setQuantity])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [joinStep])

  return (
    <Layout tunneled={true} handleTunneledBack={handleTunneledBack}>
      <Metadata noindex={true} />
      <CanClubJoinPage
        isCartLoading={location?.state?.fromCart && !lineItems}
        subscriptionContent={subscriptionContent}
      />
    </Layout>
  )
}

export const query = graphql`
  query CanClubJoinTemplate($slug: String) {
    subscriptionContent: contentfulSubscription(slug: { eq: $slug }) {
      ...SubscriptionFragment
    }
    allProductContentData: allContentfulProduct {
      nodes {
        ...ProductClubFragment
      }
    }
  }
`

export default CanClubJoinTemplate
