/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Box, Button, Flex, jsx, Spinner, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useCanClub } from '~/hooks/components/use-can-club'
import { PROMO_STORAGE } from '~/hooks/components/use-promo-codes'
import storage from '~/services/storage'
import { toUsdCurrency } from '~/utils/formatters'
import { getFormattedBottleCount } from '~/utils/products'
import { getClubCanCount, getClubTotalPrices } from '~/utils/subscriptions'

const QUANTITY_MULTIPLE = 6

const CanClubStepCartFooter = ({
  additionalFooterButtons,
  continueLabel,
  handleContinue,
  includeFooterTotals,
}) => {
  const {
    customizationError,
    customizationIsSaving,
    customizationShowError,
    products,
    quantity,
    setCustomizationError,
    setCustomizationShowError,
  } = useCanClub()
  const [canCount, setCanCount] = useState(quantity)
  const [totals, setTotals] = useState(null)
  const translate = useTranslate()

  const promoCodeStorage = storage.getItem(PROMO_STORAGE)

  useEffect(() => {
    setCanCount(getClubCanCount(products))
    setTotals(getClubTotalPrices(products))
  }, [products])

  useEffect(() => {
    const deficit = quantity - canCount
    const difference = canCount % QUANTITY_MULTIPLE

    if (deficit > 0) {
      setCustomizationError(
        `Select ${deficit} more can${
          deficit > 1 ? 's' : ''
        } for a minimum of ${quantity}.`
      )
    } else if (difference !== 0) {
      setCustomizationError(
        `Select a multiple of ${QUANTITY_MULTIPLE} cans (add ${
          QUANTITY_MULTIPLE - difference
        } or remove ${difference}).`
      )
    } else {
      setCustomizationError(null)
      setCustomizationShowError(false)
    }
  }, [canCount, quantity, setCustomizationError, setCustomizationShowError])

  return (
    <Box>
      {includeFooterTotals && (
        <Flex mb={2}>
          <Text as="p" variant="body2" sx={{ flexGrow: '1' }}>
            <Text
              as="span"
              sx={{ fontWeight: 'bold' }}
            >{`${canCount} cans`}</Text>
            <Text
              as="span"
              pl="12px"
              sx={{
                color: 'greyDark',
                fontSize: '15px',
                letterSpacing: '0.25px',
                opacity: '0.9',
                position: 'relative',
                top: '-1px',
              }}
            >{`= ${getFormattedBottleCount(canCount)} bottles`}</Text>
          </Text>
          {totals && (
            <Text as="p" variant="body1">
              <Text as="span" pl={1} sx={{ textDecoration: 'line-through' }}>
                {toUsdCurrency(totals.regular)}
              </Text>
              <Text
                as="span"
                color="brandTertiaryDark"
                pl={1}
                sx={{ fontWeight: 'bold' }}
              >
                {toUsdCurrency(totals.discount)}
              </Text>
            </Text>
          )}
        </Flex>
      )}
      {includeFooterTotals && promoCodeStorage && (
        <Text as="p" color="greyDark" variant="finePrint" mt={-1} mb={2}>
          {translate('club_promo')}
        </Text>
      )}
      <Box mt={1}>
        {customizationError && customizationShowError && (
          <Text as="p" variant="body3" color="error" mt={-1} mb={2}>
            {customizationError}
          </Text>
        )}
        {additionalFooterButtons?.map((button, index) => {
          return (
            <Box
              key={`footer-button-${index}`}
              mb={2}
              pb={2}
              sx={{
                borderBottom: '1px solid',
                borderColor: 'greyLight',
                textAlign: 'center',
              }}
            >
              {button}
            </Box>
          )
        })}
        <Button
          disabled={customizationError && customizationShowError}
          onClick={() => handleContinue()}
          sx={{ width: '100%' }}
        >
          <Text as="span">
            {!customizationIsSaving && continueLabel}
            {customizationIsSaving && (
              <Spinner
                size="24"
                color="inherit"
                mb={-1}
                sx={{
                  position: 'relative',
                  top: '-2px',
                }}
              />
            )}
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

export default CanClubStepCartFooter
