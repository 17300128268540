/** @jsx jsx */
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import CanClubTypeStepOption from '~/components/CanClub/Step/Type/Option'
import { useCanClub } from '~/hooks/components/use-can-club'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'

const CanClubTypeStep = () => {
  const { joinSteps, setJoinStep, setJoinType, SUBSCRIPTION_TYPE_OPTIONS } =
    useCanClub()
  const clubTypeUiImages = useUiTextQuery('can-club', 'images')
  const clubTypeUiText = useUiTextQuery('can-club', 'type')

  const handleSetType = (type) => {
    setJoinType(type)
    setJoinStep(joinSteps[1])
  }

  return (
    <Box pt={[3, 4]} pb={[7, 8, 10]}>
      <Container>
        <Grid columns={[1, null, null, 12]}>
          <Box sx={{ gridColumn: [null, null, null, '2 / 12'] }}>
            <Box mb={[3, 4]} sx={{ textAlign: 'center' }}>
              <Heading as="h1" variant="title2" mb={1}>
                {clubTypeUiText.title}
              </Heading>
              <Text as="p" variant="body2" my={0}>
                {clubTypeUiText.description}
              </Text>
            </Box>
            <Grid columns={[1, SUBSCRIPTION_TYPE_OPTIONS.length]}>
              {SUBSCRIPTION_TYPE_OPTIONS.map((typeOption, index) => (
                <CanClubTypeStepOption
                  key={`type-panel-${typeOption.key}`}
                  handleClick={handleSetType}
                  image={clubTypeUiImages[index]}
                  label={clubTypeUiText.choices[typeOption.key]}
                  type={typeOption.key}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default CanClubTypeStep
