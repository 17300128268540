/** @jsx jsx */
import { useState } from 'react'
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import CanClubStepCart from '~/components/CanClub/Step/Cart'
import CanClubCustomizeStepCategory from '~/components/CanClub/Step/Customize/Category'
import { useCanClub } from '~/hooks/components/use-can-club'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'

const CanClubCustomizeStep = ({ continueLabel, handleContinue }) => {
  const { joinTypeOption, quantity, SUBSCRIPTION_TYPE_OPTIONS } = useCanClub()
  const [isSelectionsExpanded, setIsSelectionsExpanded] = useState(false)
  const clubCustomizeUiText = useUiTextQuery('can-club', 'customize')

  const productCategories = ['Red', 'Rosé', 'White']

  // Show whites first if that's the selected preference.
  if (joinTypeOption === SUBSCRIPTION_TYPE_OPTIONS[2]) {
    productCategories.reverse()
  }

  return (
    <Box>
      <Container>
        <Grid columns={[null, null, null, 12]}>
          <Box sx={{ gridColumn: [null, null, null, '2 / 12'] }}>
            <Grid columns={[1, null, 2]}>
              <Box pb={isSelectionsExpanded ? '560px' : '184px'}>
                <Box mt={[2, null, 4]} mb={2}>
                  <Heading as="h1" variant="title2" mb={[1, 2]}>
                    {clubCustomizeUiText.title}
                  </Heading>
                  <Text as="p" variant="body2" my={0}>
                    {clubCustomizeUiText.description?.replace(
                      '{quantity}',
                      quantity
                    )}
                  </Text>
                </Box>
                {productCategories.map((categoryName, index) => (
                  <CanClubCustomizeStepCategory
                    key={`customize-category-${categoryName}`}
                    categoryName={categoryName}
                  />
                ))}
              </Box>
              <Box>
                <CanClubStepCart
                  continueLabel={continueLabel}
                  handleContinue={handleContinue}
                  includeSelections={true}
                  isSelectionsExpanded={isSelectionsExpanded}
                  productCategories={productCategories}
                  setIsSelectionsExpanded={setIsSelectionsExpanded}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default CanClubCustomizeStep
