/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import ProductTags from '~/components/Product/Tags'

const ListingCardSpecs = ({
  description,
  descriptionVariant,
  footer,
  image,
  isNarrow = false,
  subtitle,
  subtitleVariant = 'subtitle2',
  tags,
  title,
  titleVariant = 'title3',
}) => {
  const descriptionSx = {
    flexGrow: '1',
    '& p': {
      my: 1,
      variant: 'text.body3',
    },
    '& p:first-of-type': {
      mt: 0,
    },
    '& p:last-of-type': {
      mb: 0,
    },
  }

  let descriptionVariantSx = {}
  if (descriptionVariant === 'included') {
    descriptionVariantSx = {
      '& h3': {
        variant: 'text.title6',
        mt: 0,
        mb: 2,
      },
      '& h4': {
        variant: 'text.body3',
        fontWeight: 'bold',
        mt: 2,
        mb: 0,
      },
      '& p': {
        variant: 'text.description',
        color: 'greyDark',
        my: 0,
      },
    }
  }

  return (
    <Flex
      bg="white"
      p={2}
      sx={{ borderRadius: '10px', height: '100%', flexDirection: 'column' }}
    >
      <Grid
        columns={[1, !isNarrow && 2]}
        mb={2}
        sx={{ alignItems: 'center', gridColumnGap: 2, gridRowGap: 3 }}
      >
        {image && (
          <Box sx={{ borderRadius: '10px', overflow: 'hidden' }}>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </Box>
        )}
        <Box>
          <Heading as="h3" variant={titleVariant}>
            {title}
          </Heading>
          {subtitle && (
            <Text
              as="h4"
              variant={subtitleVariant}
              mt={1}
              sx={
                subtitleVariant === 'body3' && {
                  color: 'greyDark',
                  fontWeight: 'normal',
                }
              }
            >
              {subtitle}
            </Text>
          )}
        </Box>
      </Grid>
      {tags && (
        <Box mb={2}>
          <ProductTags tags={tags} />
        </Box>
      )}
      {description && (
        <Box
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
          bg="backgroundLight"
          p={[2, 3]}
          sx={{ ...descriptionSx, ...descriptionVariantSx }}
        />
      )}
      {footer}
    </Flex>
  )
}

export default ListingCardSpecs
