/** @jsx jsx */
import { Fragment } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  jsx,
  Text,
} from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import CanClubStepCart from '~/components/CanClub/Step/Cart'
import CanClubDefaultStepItem from '~/components/CanClub/Step/Default/Item'
import { useCanClub } from '~/hooks/components/use-can-club'
import { PROMO_STORAGE } from '~/hooks/components/use-promo-codes'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'
import storage from '~/services/storage'
import { toUsdCurrency } from '~/utils/formatters'
import { getClubTotalPrices } from '~/utils/subscriptions'

const CanClubDefaultStep = ({ subscriptionContent }) => {
  const { joinSteps, joinTypeOption, quantity, setJoinStep } = useCanClub()
  const translate = useTranslate()
  const clubDefaultUiText = useUiTextQuery('can-club', 'default')
  const clubTypeUiText = useUiTextQuery('can-club', 'type')

  const { discountPercentage } = subscriptionContent || {}
  const promoCodeStorage = storage.getItem(PROMO_STORAGE)

  const continueLabel = 'Select & Continue'
  const handleContinue = () => setJoinStep(joinSteps[3])

  const clubQuantityLabel = `${quantity}-Can Club — ${discountPercentage}% discount`
  const clubTypeLabel = clubTypeUiText.choices[joinTypeOption.key]

  return (
    <Box pt={[3, 4]} pb={[7, 8, 10]}>
      <Container pb={['184px', '184px', 0]}>
        <Grid columns={[1, null, null, 12]}>
          <Box sx={{ gridColumn: [null, null, null, '2 / 12'] }}>
            <Box mb={[3, 4]} sx={{ textAlign: 'center' }}>
              <Heading as="h1" variant="title2" mb={1}>
                {clubDefaultUiText.title}
              </Heading>
              <Text as="p" variant="body2" my={0}>
                {clubDefaultUiText.description}
              </Text>
            </Box>
            <Box
              bg="white"
              p={2}
              sx={{
                border: '1px solid',
                borderColor: 'greyLight',
                borderRadius: '8px',
              }}
            >
              <Box
                mb={2}
                pb={2}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'greyLight',
                }}
              >
                <Flex
                  sx={{
                    alignItems: 'center',
                    flexDirection: ['column', 'row'],
                    justifyContent: 'space-between',
                  }}
                >
                  <Flex
                    mb={[2, 0]}
                    sx={{
                      flexDirection: ['row', 'column'],
                      flexWrap: 'wrap',
                      gap: 1,
                      justifyContent: 'center',
                      order: [null, 1],
                      textAlign: [null, 'right'],
                    }}
                  >
                    <Box>
                      <Text as="p" variant="pill">
                        {clubQuantityLabel}
                      </Text>
                    </Box>
                    <Box>
                      <Text as="p" variant="pill">
                        {clubTypeLabel}
                      </Text>
                    </Box>
                  </Flex>
                  <Box
                    sx={{
                      flexShrink: 0,
                      order: [null, 0],
                      textAlign: ['center', 'left'],
                    }}
                  >
                    <Heading as="h2" mt={0} mb={1} variant="title3">
                      {clubDefaultUiText.picks}
                    </Heading>
                    <Box>
                      <CanClubDefaultStepSummary />
                      {promoCodeStorage && (
                        <Text
                          as="p"
                          mt={1}
                          variant="finePrint"
                          color="greyDark"
                        >
                          {translate('club_promo')}
                        </Text>
                      )}
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Box>
                <Grid
                  columns={[1, null, 2]}
                  sx={{ columnGrap: ['0', null, 3], rowGap: 0 }}
                >
                  <CanClubDefaultStepItems />
                </Grid>
                <Grid
                  columns={2}
                  pt={3}
                  pb={1}
                  sx={{
                    borderTop: '1px solid',
                    borderColor: 'greyLight',
                    display: ['none', null, 'grid'],
                  }}
                >
                  <Box>
                    <CanClubDefaultStepCustomizeButton />
                  </Box>
                  <Box>
                    <CanClubDefaultStepContinueButton
                      continueLabel={continueLabel}
                      handleContinue={handleContinue}
                    />
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Container>
      <Box sx={{ display: [null, null, 'none'] }}>
        <CanClubStepCart
          additionalFooterButtons={[<CanClubDefaultStepCustomizeButton />]}
          continueLabel={'Select & Continue'}
          handleContinue={handleContinue}
          includeFooterTotals={false}
        />
      </Box>
    </Box>
  )
}

const CanClubDefaultStepSummary = () => {
  const { products, quantity } = useCanClub()

  const totalPrices = getClubTotalPrices(products)

  return (
    <Text as="p" my={0} variant="body3">
      <Text as="strong" mr={1}>
        {`${quantity} cans (${quantity / 3} bottle equivalent):`}
      </Text>
      {totalPrices.regular && (
        <Text as="span" mr={1} sx={{ textDecoration: 'line-through' }}>
          {toUsdCurrency(totalPrices.regular)}
        </Text>
      )}
      {totalPrices.discount && (
        <Text as="strong" color="brandTertiaryDark">
          {toUsdCurrency(totalPrices.discount)}
        </Text>
      )}
    </Text>
  )
}

const CanClubDefaultStepItems = () => {
  const { products } = useCanClub()

  return Object.entries(products).map(([sku, item]) => {
    if (item.quantity < 1) return <Fragment key={`default-item-${sku}`} />

    return (
      <Box
        mb={[2, 1]}
        pb={[2, 1, 2, 1]}
        key={`default-item-${sku}`}
        sx={{
          borderBottom: ['1px solid', 'none'],
          borderColor: 'greyLight',
          '&:nth-last-of-type(-n + 1)': { borderBottom: 'none' },
          '&:nth-last-of-type(-n + 2)': { borderBottom: [null, null, 'none'] },
        }}
      >
        <CanClubDefaultStepItem item={item} />
      </Box>
    )
  })
}

const CanClubDefaultStepContinueButton = ({
  continueLabel,
  handleContinue,
}) => {
  return (
    <Button onClick={() => handleContinue()} sx={{ width: '100%' }}>
      {continueLabel}
    </Button>
  )
}

const CanClubDefaultStepCustomizeButton = () => {
  const { joinSteps, setJoinStep, setCustomizationIsCustomized } = useCanClub()

  const handleStartCustomizing = () => {
    setCustomizationIsCustomized(true)
    setJoinStep(joinSteps[2])
  }

  return (
    <Button
      variant="secondary"
      onClick={() => handleStartCustomizing()}
      sx={{ width: '100%' }}
    >
      {'Customize'}
    </Button>
  )
}

export default CanClubDefaultStep
