/** @jsx jsx */
import { useEffect } from 'react'
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import CanClubReviewStepSummary from '~/components/CanClub/Step/Review/Summary'
import CheckoutButton from '~/components/Checkout/Button'
import { useAddSubscriptionToCart } from '~/hooks/components/use-add-subscription-to-cart'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'
import {
  getAllCartPromotions,
  getAllCartPromotionsForDisplay,
} from '~/utils/promotions'

const CanClubReviewStep = ({ subscriptionContent }) => {
  const { addSubscriptionToCart, error, hasAddedToCart, isAddingToCart } =
    useAddSubscriptionToCart()
  const { cart } = useCart()
  const clubReviewUiText = useUiTextQuery('can-club', 'review')

  const { name } = subscriptionContent || {}
  const { displayItemTotal, displayTotal } = cart

  const allPromotions = getAllCartPromotions(cart)
  const displayPromotions = getAllCartPromotionsForDisplay(allPromotions)

  useEffect(() => {
    // Products need to be added to the cart now in order to apply any promos.
    // If the user bails from the flow, the Cart Flyout will remove these items.
    if (!hasAddedToCart && !isAddingToCart && !error) {
      addSubscriptionToCart()
    }
  }, [addSubscriptionToCart, error, hasAddedToCart, isAddingToCart])

  return (
    <Box pt={[3, 4]} pb={[7, 8, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box sx={{ gridColumn: [null, '2 / 12', '3 / 11', '4 / 10'] }}>
            <Box mb={[3, 4]} sx={{ textAlign: 'center' }}>
              <Heading as="h1" variant="title2" mb={1}>
                {clubReviewUiText.title}
              </Heading>
              <Text as="p" variant="body2" my={0}>
                <Text as="strong" sx={{ color: 'brandTertiary' }}>
                  {clubReviewUiText.lead}
                </Text>{' '}
                {clubReviewUiText.description}
              </Text>
            </Box>
            <Box
              bg="white"
              sx={{
                border: '1px solid',
                borderColor: 'greyLight',
                borderRadius: '8px',
              }}
            >
              <Box py={2} px={4} sx={{ textAlign: 'center' }}>
                <Heading as="h2" variant="callout0">
                  {`${name} Membership`}
                </Heading>
              </Box>
              <CanClubReviewStepSummary
                displayItemTotal={displayItemTotal}
                displayPromotions={displayPromotions}
                displayTotal={displayTotal}
                isLoading={isAddingToCart}
              />
              <Box px={[2, 3]} pb={2}>
                <CheckoutButton
                  includeSmsOptIn={true}
                  isDisabled={isAddingToCart || error}
                />
                {error && (
                  <Text as="p" variant="error" mt={2}>
                    {error}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default CanClubReviewStep
