/** @jsx jsx */
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import CanClubStepCartFooter from '~/components/CanClub/Step/Cart/Footer'
import CanClubStepCartSelections from '~/components/CanClub/Step/Cart/Selections'

const CanClubStepCart = ({
  additionalFooterButtons,
  continueLabel,
  handleContinue,
  includeFooterTotals = true,
  includeSelections,
  isSelectionsExpanded,
  productCategories,
  setIsSelectionsExpanded,
}) => {
  return (
    <Flex
      bg="white"
      ml={[null, null, 3, 6]}
      sx={{
        borderLeft: [null, null, '1px solid'],
        borderRight: [null, null, null, '1px solid'],
        borderColor: [null, null, 'greyLight', 'greyLight'],
        bottom: '0',
        flexDirection: 'column',
        height: ['auto', null, 'calc(100vh - 70px)'],
        left: ['0', null, 'auto'],
        overflow: [null, null, 'hidden'],
        position: ['fixed', null, 'sticky'],
        right: ['0', null, 'auto'],
        top: [null, null, '70px'],
        width: '100%',
        zIndex: '200',
      }}
    >
      {includeSelections && (
        <Box
          sx={{
            boxShadow: ['0px 5px 15px 0px rgba(0, 0, 0, 0.4)', null, 'none'],
            flexGrow: '1',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'greyLight',
              display: [null, null, 'none'],
            }}
          >
            <CanClubStepCartToggle
              isSelectionsExpanded={isSelectionsExpanded}
              setIsSelectionsExpanded={setIsSelectionsExpanded}
            />
          </Box>
          <Box
            sx={{
              height: [isSelectionsExpanded ? '364px' : '0', null, '100%'],
              overflow: 'scroll',
              transition: ['height 0.2s ease', null, 'none'],
            }}
          >
            <CanClubStepCartSelections productCategories={productCategories} />
          </Box>
        </Box>
      )}
      <Box
        bg="white"
        px={3}
        py={2}
        sx={{
          boxShadow: includeSelections
            ? '0px 5px 15px 0px rgba(0, 0, 0, 0.2)'
            : '0px 5px 15px 0px rgba(0, 0, 0, 0.4)',
        }}
      >
        <CanClubStepCartFooter
          additionalFooterButtons={additionalFooterButtons}
          continueLabel={continueLabel}
          handleContinue={handleContinue}
          includeFooterTotals={includeFooterTotals}
        />
      </Box>
    </Flex>
  )
}

const CanClubStepCartToggle = ({
  isSelectionsExpanded,
  setIsSelectionsExpanded,
}) => {
  return (
    <Button
      variant="reset"
      onClick={() => setIsSelectionsExpanded(!isSelectionsExpanded)}
      py="12px"
      sx={{
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Text
        as="span"
        color="brandPrimary"
        variant="body3"
        sx={{ fontWeight: 'medium' }}
      >
        {`${isSelectionsExpanded ? 'Hide' : 'Show'} selected wines`}
      </Text>
    </Button>
  )
}

export default CanClubStepCart
