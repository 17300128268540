import React, { Fragment } from 'react'
import { Box, Spinner } from 'theme-ui'
import CanClubCustomizeStep from '~/components/CanClub/Step/Customize'
import CanClubDefaultStep from '~/components/CanClub/Step/Default'
import CanClubReviewStep from '~/components/CanClub/Step/Review'
import CanClubTypeStep from '~/components/CanClub/Step/Type'
import { useCanClub } from '~/hooks/components/use-can-club'

const CanClubJoinPage = ({ isCartLoading, subscriptionContent }) => {
  const {
    customizationError,
    joinStep,
    joinSteps,
    setCustomizationShowError,
    setJoinStep,
  } = useCanClub()

  const handleCustomizationContinue = () => {
    if (customizationError) {
      setCustomizationShowError(true)
      return
    }

    setJoinStep(joinSteps[3])
  }

  return (
    <Fragment>
      {isCartLoading && (
        <Box py={10} sx={{ textAlign: 'center' }}>
          <Spinner
            color="brandTertiary"
            size="100"
            sx={{
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        </Box>
      )}
      {!isCartLoading && (
        <Fragment>
          {joinStep === joinSteps[0] && <CanClubTypeStep />}
          {joinStep === joinSteps[1] && (
            <CanClubDefaultStep subscriptionContent={subscriptionContent} />
          )}
          {joinStep === joinSteps[2] && (
            <CanClubCustomizeStep
              continueLabel={'Save & Continue'}
              handleContinue={() => handleCustomizationContinue()}
            />
          )}
          {joinStep === joinSteps[3] && (
            <CanClubReviewStep subscriptionContent={subscriptionContent} />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default CanClubJoinPage
