/** @jsx jsx */
import { Fragment } from 'react'
import { Box, Heading, jsx, Text } from 'theme-ui'
import { useCanClub } from '~/hooks/components/use-can-club'
import useUiTextQuery from '~/hooks/graphql/queries/use-ui-text'
import { toUsdCurrency } from '~/utils/formatters'
import { sortProductsByCategory } from '~/utils/subscriptions'

const CanClubStepCartSelections = ({ productCategories }) => {
  const { products } = useCanClub()
  const clubCustomizeUiText = useUiTextQuery('can-club', 'customize')

  let sortedProducts = sortProductsByCategory(productCategories, products)

  return (
    <Box>
      <Box
        bg="backgroundMedium"
        py={5}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'greyLight',
          display: ['none', null, 'block'],
          textAlign: 'center',
        }}
      >
        <Heading as="h2" variant="callout0">
          {clubCustomizeUiText.selected}
        </Heading>
      </Box>
      <Box sx={{ overflowY: 'auto' }}>
        <Box as="table" sx={{ width: '100%' }}>
          <Box as="tbody">
            {Object.entries(sortedProducts)
              .filter(([sku, { quantity }]) => quantity > 0)
              .map(([sku, item], index) => (
                <Box
                  as="tr"
                  key={`cart-item-${sku}-${index}`}
                  bg={index % 2 === 0 && 'backgroundLight'}
                >
                  <CanClubStepCartSelectionsItem item={item} sku={sku} />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const CanClubStepCartSelectionsItem = ({ item, sku }) => {
  const { name, price, quantity } = item

  return (
    <Fragment>
      <Box as="td" py="12px" pl={[2, null, 3]}>
        <Text as="p" m={0} variant="body2" sx={{ flexGrow: '1' }}>
          {name}
        </Text>
      </Box>
      <Box as="td" py="12px">
        <Text
          as="p"
          m={0}
          mr={1}
          variant="body2"
          sx={{
            fontWeight: 'bold',
            minWidth: '40px',
            textAlign: 'right',
          }}
        >
          {`x${quantity}`}
        </Text>
      </Box>
      <Box as="td" py="12px" pr={[2, null, 3]} sx={{ textAlign: 'right' }}>
        <Text as="p" variant="body2" sx={{ flexShrink: '0' }}>
          <Text
            as="span"
            color="greyDark"
            pr={1}
            sx={{ textDecoration: 'line-through' }}
          >
            {toUsdCurrency(price.regular * quantity)}
          </Text>
          <Text
            as="span"
            color="brandTertiaryDark"
            pr="6px"
            sx={{ fontWeight: 'bold' }}
          >
            {toUsdCurrency(price.discount * quantity, true)}
          </Text>
        </Text>
      </Box>
    </Fragment>
  )
}

export default CanClubStepCartSelections
