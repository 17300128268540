/** @jsx jsx */
import { Box, Heading, jsx, Text } from 'theme-ui'
import CanClubCustomizeStepCategoryItem from '~/components/CanClub/Step/Customize/Item'
import { useCanClub } from '~/hooks/components/use-can-club'
import { sortProductsByCategory } from '~/utils/subscriptions'

const CanClubCustomizeStepCategory = ({ categoryName }) => {
  const { products } = useCanClub()

  const categoryProducts = sortProductsByCategory([categoryName], products)

  return (
    <Box mb={4}>
      <Heading as="h2" variant="title3">
        {`${categoryName} wines`}
      </Heading>
      {categoryProducts.length > 0 &&
        categoryProducts.map((item, index) => (
          <Box
            key={`category-item-${item.sku}-${index}`}
            mt={1}
            pt={1}
            sx={{
              borderTop: '1px solid',
              borderColor: 'greyLight',
            }}
          >
            <CanClubCustomizeStepCategoryItem item={item} />
          </Box>
        ))}
      {categoryProducts.length === 0 && (
        <Box mt={2}>
          <Text as="p" variant="body2">
            {`We don't currently have any ${categoryName} wines available.`}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default CanClubCustomizeStepCategory
