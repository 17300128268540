/** @jsx jsx */
import { Box, Flex, jsx, Text } from 'theme-ui'

const ProductTagsTag = ({ tag }) => {
  return (
    <Box
      key={tag}
      as="li"
      m="0 12px 8px -4px"
      p="4px 16px"
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'greyLight',
        borderRadius: '20px',
        color: 'greyDark',
        listStyleType: 'none',
      }}
    >
      <Text as="p" variant="description">
        {tag}
      </Text>
    </Box>
  )
}

const ProductTags = ({ tags }) => {
  return (
    tags && (
      <Flex
        as="ul"
        m={0}
        mb={-1}
        p={0}
        sx={{
          flexWrap: 'wrap',
        }}
      >
        {tags.map((tag, index) => (
          <ProductTagsTag key={index} tag={tag} />
        ))}
      </Flex>
    )
  )
}

export default ProductTags
