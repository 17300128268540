import { useState } from 'react'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useAddToCart } from '~/hooks/components/use-add-to-cart'
import { useCanClub } from '~/hooks/components/use-can-club'
import { useEmptyCart } from '~/hooks/components/use-empty-cart'
import { useGeolocation } from '~/hooks/components/use-geolocation'
import { useOrderTags } from '~/hooks/components/use-order-tags'
import { applyPromoCodesFromStorage } from '~/hooks/components/use-promo-codes'
import { TAGS } from '~/utils/lists/order-tags'
import { getClubJoinNewLineItems } from '~/utils/subscriptions'

export function useAddSubscriptionToCart() {
  const { getAddToCartDataBySku } = useAddToCart()
  const { joinInterval, joinTypeOption, products, quantity } = useCanClub()
  const { addPromoCode, addToCart } = useCart()
  const { emptyCart } = useEmptyCart()
  const { checkGeolocation } = useGeolocation()
  const { addTags } = useOrderTags()
  const translate = useTranslate()

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [hasAddedToCart, setHasAddedToCart] = useState(false)
  const [error, setError] = useState(false)

  const addSubscriptionToCart = async () => {
    setIsAddingToCart(true)
    setError(null)

    try {
      const items = getClubJoinNewLineItems(products, joinInterval)
      const giftSku =
        quantity === 24
          ? process.env.GATSBY_CLUB_SIGNUP_24_GIFT_SKU
          : process.env.GATSBY_CLUB_SIGNUP_12_GIFT_SKU
      const giftKit = await getAddToCartDataBySku(giftSku)
      const orderTags = [TAGS[`CLUB_${quantity}_NEW`]]

      await emptyCart(true)
      await addToCart(items)

      // The signup gift is added in code because promotions can't bundle.
      if (Object.keys(giftKit).length) {
        await addToCart({
          lineItem: {
            sku: giftSku,
            quantity: 1,
            options: { parts: giftKit.parts },
          },
        })
      }

      if (joinTypeOption?.tag?.length) {
        orderTags.push(joinTypeOption.tag)
      }

      // The first order's tags will get copied to the subscription, which is
      // important for promotions, etc. The tag is added here - instead of the
      // chord-order-tags webhook function - so that they exist when that
      // subscription is created.
      await addTags({ tags: orderTags })

      // Attempt to reapply any promo codes that didn't previously qualify.
      await applyPromoCodesFromStorage({ addPromoCode })

      setHasAddedToCart(true)
      setIsAddingToCart(false)
    } catch (error) {
      setError(translate('error.api.default'))
      setIsAddingToCart(false)
    }

    try {
      checkGeolocation()
    } catch (error) {}
  }

  return {
    addSubscriptionToCart,
    error,
    hasAddedToCart,
    isAddingToCart,
  }
}
