/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Flex, Heading, jsx } from 'theme-ui'

const CanClubTypeStepOption = ({ handleClick, image, label, type }) => {
  return (
    <Flex
      bg="white"
      onClick={() => handleClick(type)}
      p={[0, 2]}
      sx={{
        alignItems: [null, 'center'],
        border: '1px solid',
        borderColor: 'greyLight',
        borderRadius: '8px',
        cursor: 'pointer',
        flexDirection: [null, 'column'],
        overflow: 'hidden',
        textAlign: 'center',
      }}
    >
      <Heading
        as="h2"
        variant="callout0"
        m={0}
        mb={2}
        sx={{ display: ['none', 'block'], flexGrow: '1' }}
      >
        {label}
      </Heading>
      <Box
        mb={[0, 2]}
        sx={{
          borderRadius: [null, '8px'],
          flexShrink: '0',
          height: ['128px', 'auto'],
          overflow: 'hidden',
          width: ['128px', '100%'],
        }}
      >
        <GatsbyImage image={image?.gatsbyImageData} alt={image?.description} />
      </Box>
      <Flex
        p={[2, 0]}
        pt="20px"
        sx={{
          flexDirection: 'column',
          flexGrow: ['1', '0'],
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Heading
          as="h2"
          variant="callout0"
          m={0}
          mb={2}
          sx={{ display: [null, 'none'] }}
        >
          {label}
        </Heading>
        <Button
          onClick={() => handleClick(type)}
          sx={{ width: [null, null, '100%'] }}
        >
          {'Select'}
        </Button>
      </Flex>
    </Flex>
  )
}

export default CanClubTypeStepOption
