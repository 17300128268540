/** @jsx jsx */
import { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import Modal from '~/components/Generic/Modal'
import ListingCardSpecs from '~/components/Listing/Card/Specs'
import { toUsdCurrency } from '~/utils/formatters'

const CanClubDefaultStepItem = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { description, image, name, price, product, quantity } = item

  return (
    <Flex
      sx={{ alignItems: [null, 'center'], flexDirection: ['column', 'row'] }}
    >
      <Box
        onClick={() => setIsExpanded(true)}
        mr={2}
        sx={{
          cursor: 'pointer',
          display: ['none', 'block', 'none', 'block'],
          flexShrink: '0',
          height: '126px',
          width: '126px',
        }}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.description}
          style={{
            borderRadius: '8px',
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
      <Box sx={{ flexGrow: [null, '1'] }}>
        <Flex sx={{ alignItems: 'center', width: '100%' }} mb={1}>
          <Box
            onClick={() => setIsExpanded(true)}
            mr={1}
            sx={{
              cursor: 'pointer',
              display: ['block', 'none', 'block', 'none'],
              flexShrink: '0',
              height: ['64px', null, '98px'],
              width: ['64px', null, '98px'],
            }}
          >
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description}
              style={{
                borderRadius: '8px',
                height: '100%',
                width: '100%',
              }}
            />
          </Box>
          <Flex
            sx={{
              flexGrow: '1',
              flexWrap: ['wrap', null, null, 'nowrap'],
              width: '100%',
            }}
          >
            <Box
              sx={{
                flexGrow: ['1', null, null, '0'],
                flexShrink: ['0', null, null, '1'],
                '@media only screen and (max-width: 460px)': { width: '100%' },
                '@media only screen and (min-width: 832px) and (max-width: 1023px)':
                  { width: '100%' },
              }}
            >
              <Button
                variant="reset"
                onClick={() => setIsExpanded(true)}
                mb="2px"
                sx={{ display: 'block', textAlign: 'left', width: '100%' }}
              >
                <Text
                  as="h3"
                  variant="body3"
                  sx={{ flexGrow: '1', fontWeight: 'medium' }}
                >{`${name} (${quantity} cans)`}</Text>
              </Button>
              <Text as="p" variant="body3">
                <Text
                  as="span"
                  color="greyDark"
                  pr={1}
                  sx={{ textDecoration: 'line-through' }}
                >
                  {toUsdCurrency(price.regular, true)}
                </Text>
                <Text
                  as="strong"
                  color="brandTertiaryDark"
                  pr="6px"
                  sx={{ fontWeight: 'bold' }}
                >
                  {toUsdCurrency(price.discount, true)}
                </Text>
                <Text as="span" pr="6px">
                  {'/'}
                </Text>
                <Text as="span">{'250mL can'}</Text>
              </Text>
            </Box>
            <Text
              color="brandPrimary"
              mt={[0, null, 1, 0]}
              pl={[null, null, null, 1]}
              variant="body3"
              onClick={() => setIsExpanded(true)}
              sx={{
                cursor: 'pointer',
                flexGrow: [null, null, null, '1'],
                textAlign: 'right',
                textDecoration: 'underline',
                '@media only screen and (min-width: 832px) and (max-width: 1023px)':
                  { mt: 1 },
              }}
            >
              {'Details'}
            </Text>
          </Flex>
        </Flex>
        {description && (
          <Box
            variant="text.description"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
            sx={{ color: 'greyDark', p: { m: 0 } }}
          />
        )}
      </Box>
      {isExpanded && (
        <Modal onClose={() => setIsExpanded(false)} maxWidth="474px">
          <ListingCardSpecs
            description={product.kitDescription}
            image={product.mainImage}
            subtitle={product.subtitle}
            tags={product.tags}
            title={product.name}
          />
        </Modal>
      )}
    </Flex>
  )
}

export default CanClubDefaultStepItem
